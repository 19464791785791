.popupMenu {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.popupMenu .triangle {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #464646;
}

.popupMenu .optionBox {
  background: #464646;
  padding: 27px;
  box-sizing: border-box;
  width: 100%;
}

.popupMenu .optionBox .option {
  font-size: 16px;
  cursor: pointer;
  color: #ADADAD;
  margin: 18px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popupMenu .optionBox .option:first-child {
  margin-top: 0;
}

.popupMenu .optionBox .option:last-child {
  margin-bottom: 0;
}

.popupMenu .optionBox .option.selected {
  color: #fff;
}

