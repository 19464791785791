.atAGlanceModule {
  margin-bottom: 23px;
  color: #464646;
  overflow: hidden;
  flex-grow: 1;
}

.atAGlanceModule h3 {
  margin: 23px 33px 10px 33px;
  font-weight: normal;
  font-size: 26px;
}

.atAGlanceModule .expandingContainer {
  background: #F8F8F8;
  height: 0;
  overflow: hidden;
}

.atAGlanceModule.expanded .expandingContainer {
  height: auto;
}

.atAGlanceModule .atAGlanceFooter {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  font-size: 16px;
  color: #ADADAD;
  background: #F8F8F8;
  cursor: pointer;
}

.atAGlanceModule.expanded .atAGlanceFooter {
  margin-top: -40px;
}

.atAGlanceModule .atAGlanceFooter .icon {
  width: 17px;
  height: 17px;
  margin-right: 9px;
  background: url("../../images/Plus Circle Icon.svg");
}

.atAGlanceModule.expanded .atAGlanceFooter .icon {
  /* TODO: Replace with minus icon once that exists */
  background: url("../../images/Plus Circle Icon.svg");
}