.editSourcesForm h3 {
  margin-top: 0;
}

.editSourcesForm label {
  color: #464646;
  font-size: 14px;
  margin-bottom: 2px;
  display: block;
}

.editSourcesForm textarea {
  width: 300px;
  height: 100px;
}

.editSourcesForm textarea, .editSourcesForm select {
  margin-bottom: 10px;
  display: block;
}

.editSourcesForm input[type=submit] {
  margin-top: 25px;
}