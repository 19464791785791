.questionnaire {
  margin: 50px;
}

.loadingIndicator {
  margin-top: 75px;
  border-top: 1px solid #F0F0F0;
  padding-top: 25px;
  color: #464646;
  font-size: 16px;
}

.questionnaire h2 {
  font-weight: normal;
  font-size: 26px;
  color: #464646;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 60px;
}

.questionnaire .icon {
  background: url(../../images/Globe.svg);
  background-size: 100% 100%;
  margin-right: 35px;
  width: 80px;
  height: 80px;
}

.questionnaire .mainContainer {
  padding: 1px 100px 69px 110px;
}

.questionnaire .inputPage {
  display: none;
}

.questionnaire .inputPage.selected {
  display: block;
}

.questionnaire .explainerText {
  font-size: 26px;
  margin-top: 65px;
  margin-bottom: 55px;
  color: #464646;
}

.questionnaire .submitContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 61px;
  border-top: 1px solid #EAEAEA;
}

.questionnaire .submitContainer p {
  color: #464646;
  font-size: 26px;
  line-height: 32px;
  margin: 0;
}

.questionnaire .submitContainer .discardChangesButton {
  background: #d08585;
  margin-right: 25px;
}

.questionnaire .inputGroup {
  margin-top: 29px;
  border-top: 1px solid #F0F0F0;
}

.questionnaire .inputGroup h3 {
  margin-top: 30px;
  margin-bottom: 50px;
  font-weight: normal;
  display: flex;
  flex-flow: row nowrap;
  color: #6E6E6E;
}

.questionnaire .inputGroup h3 .title {
  font-size: 24px;
  flex: 0 0 210px;
  width: 210px;
}

.questionnaire .inputGroup h3 .title .showHideAssumptions {
  font-size: 14px;
  cursor: pointer;
  color: #85d0d0;
  padding-left: 10px;
}

.questionnaire .inputGroup h3 .subtitle {
  font-size: 18px;
  margin-left: 28px;
}

.questionnaire .inputSectionContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  margin: 15px 0;
}

.questionnaire .inputGroup.assumptions .inputSectionContainer {
  display: none;
}

.questionnaire .showAssumptions .inputGroup.assumptions .inputSectionContainer {
  display: flex;
}

.questionnaire .inputSectionContainer h4 {
  margin: 0;
  flex: 0 0 210px;
  width: 210px;
  font-size: 16px;
  font-weight: normal;
  color: #6E6E6E;
}

.questionnaire .inputSectionItems {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

.questionnaire .metadataItems {
  justify-content: stretch;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}

.questionnaire .metadataItems .inputItem {
  flex: 0 0 auto;
}

.questionnaire .metadataItems .datasetNameItem {
  flex: 1 0 auto;
}

.questionnaire .metadataItems .datasetNameItem .inputFieldContainer {
  width: auto;
}
