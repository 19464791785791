.atAGlanceRow {
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 33px;
}

.atAGlanceRow h4 {
  margin: 0 45px 0 0;
  font-size: 16px;
  width: 70px;
  font-family: PostGrotesk-Medium, sans-serif;
  font-weight: normal;
}

.atAGlanceRow .numberContainer {
  padding: 0 30px;
  border-right: 1px solid #ADADAD;
  text-align: center;
}

.atAGlanceRow .numberContainer .value {
  font-size: 30px;
  margin-bottom: 4px;
}

.atAGlanceRow .numberContainer .caption {
  font-size: 16px;
  margin-bottom: 23px;
}

.atAGlanceRow .numberContainer .summary {
  font-size: 16px;
}

.atAGlanceRow .earthsContainer {
  padding: 15px 30px;
}

.atAGlanceRow .earthsContainer .icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.atAGlanceRow .earthsContainer .icons div {
  width: 53px;
  height: 53px;
  margin-right: 8px;
  margin-bottom: 26px;
}

.atAGlanceRow .earthsContainer .icons div div {
  width: 100%;
  height: 100%;
  background-image: url("../../images/Globe.svg");
  background-size: auto 100%;
}