.navHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  height: 87px;
  background: #BAE3DD;
  background: linear-gradient(to right, #CAE6DF 0%, #A1D5D5 100%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  position: relative;
}

.navHeader .navLogo {
  margin-left: 52px;
  font-size: 16px;
  font-family: PostGrotesk-Medium, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #464646;
  cursor: pointer;
}

.navHeader .navItems {
  display: flex;
  align-items: center;
}

.navHeader .navItems span {
  margin-right: 33px;
  color: #464646;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
}

.navHeader .navItems .selected {
  font-family: PostGrotesk-Medium, sans-serif;
  border-bottom: 1px solid #101010;
}

.navHeader .navRightButton {
  height: 39px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 39px;
  color: #464646;
  border: 1px solid #464646;
  border-radius: 19px;
  margin-right: 51px;
  cursor: pointer;
}
