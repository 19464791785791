.editUserForm h3 {
  margin-top: 0;
}

.editUserForm label, .editUserForm input {
  display: block;
  font-size: 14px;
  color: #464646;
}

.editUserForm label, .editUserForm input[type=submit] {
  margin-top: 15px;
}

.editUserForm input[type=text] {
  width: 200px;
  border-width: 0;
  border-bottom: 1px solid #D0D0D0;
}

.editUserForm label.inline {
  display: inline-block;
  margin-right: 10px;
}

.editUserForm td, .editUserForm th {
  padding: 5px 10px;
}

.editUserForm th {
  font-family: PostGrotesk-Medium, sans-serif;
  font-weight: normal;
}

.editUserForm tbody tr {
  cursor: pointer;
}

.editUserForm tbody tr.activeDataset, .editUserForm tbody tr.activeDataset:hover {
  background: #A1D5D5;
}

.editUserForm tbody tr:hover {
  background: #ededed;
}
