.termsPage {
  font-family: PostGrotesk-Light, sans-serif;
  color: #464646;
  font-size: 18px;
  padding: 72px 266px 30px 266px;
  background: white;
}

.termsPage h2 {
  font-weight: normal;
  font-size: 26px;
  margin-bottom: 68px;
  margin-top: 0;
}

.termsPage h3 {
  text-transform: uppercase;
}

.termsPage h3, h4 {
  font-family: PostGrotesk-Medium, sans-serif;
}

.termsPage p {
  line-height: 24px;
}