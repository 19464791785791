.footer {
  height: 60px;
  background: #444;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.footer .leftItems, .footer .rightItems {
  display: flex;
  flex-flow: row nowrap;
}

.footer .leftItems div {
  margin-right: 36px;
  color: #FFF;
}

.footer .leftItems div.hidden {
  display: none;
}

.footer .rightItems div {
  margin-left: 36px;
  color: #ADADAD;
  cursor: pointer;
}
