.whitelistCodes {
  padding: 25px;
  color: #464646;
}

.whitelistCodes h3 {
  margin-top: 0;
}

.whitelistCodes .addCodesButton {
  cursor: pointer;
  height: 30px;
  border-radius: 15px;
  padding: 0 10px;
  background: #85d0d0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin-bottom: 25px;
}

.whitelistCode {
  display: flex;
  font-size: 14px;
}

.whitelistCode div {
  margin-right: 10px;
}

.whitelistCode .code {
  font-family: monospace;
}