.deleteDatasetConfirm .buttonContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 50px;
}

.deleteDatasetConfirm .deleteButton {
  background: #d08585;
}

.deleteDatasetConfirm .error {
  color: red;
}