.questionnaire .inputItem {
  margin-left: 28px;
  margin-bottom: 15px;
  position: relative;
}

.questionnaire .inputItem .labelLine {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  color: #919191;
  font-size: 12px;
}

.questionnaire .inputItem .labelLine .sourcesContainer {
  color: #83C6B4;
  cursor: pointer;
}

.questionnaire .inputItem .labelLine .sourcesContainer .text {
  visibility: hidden;
}

.questionnaire .inputItem:hover .labelLine .sourcesContainer .text {
  visibility: visible;
}

.questionnaire .inputItem label {
  max-width: 100pt;
}

.questionnaire .inputItem .labelLine .tagIcon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-image: url("../../images/Tag_Icon.png");
  background-size: 100%;
  visibility: hidden;
}

.questionnaire .inputItem:not(:hover) .labelLine .withSources .tagIcon {
  width: 11px;
  height: 11px;
  background-image: url("../../images/Checkmark_Icon.png");
  visibility: visible;
}

.questionnaire .inputItem:hover .labelLine .tagIcon {
  visibility: visible;
}

.questionnaire .inputFieldContainer {
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 56px;
  width: 181px;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  margin: 5pt 0;
  padding: 0 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
}

.questionnaire .inputFieldContainer.withData {
  border-color: #7B7B7B;
}

.questionnaire .inputFieldContainer input {
  font-family: PostGrotesk-Light, sans-serif;
  border: none;
  color: #464646;
  width: 50px; /* This sets the minimum width below the UA default */
  flex: 1 1 auto;
  font-size: 16px;
  margin-right: 5px;
}

.questionnaire .inputFieldContainer input::placeholder {
  color: #ADADAD;
}

.questionnaire .inputUnitLabel {
  color: #ADADAD;
  font-size: 12px;
  flex: 0 0 auto;
}
