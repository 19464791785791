.gridLines {
  position: absolute;
  height: 100%;
  width: 100%;
}

.gridLines .gridLine {
  position: absolute;
  text-align: right;
  display: flex;
  justify-content: stretch;
  width: 100%;
}

.gridLines .gridLine .value {
  width: 100px;
  padding-right: 20px;
  box-sizing: border-box;
  color: #464646;
}

.gridLines .gridLine .line {
  border-bottom: 1px solid #D0D0D0;
  flex-grow: 1;
}