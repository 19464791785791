.chartModule .legendScrollContainer {
  position: relative;
  margin-left: 31px;
  flex-grow: 1;
}

.chartModule .legend {
  overflow: auto;
  padding: 5px 15px 20px 22px;
  max-height: 280px;
}

.chartModule .legendScrollContainer .bottomFade {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
}

.chartModule .valueContainer {
  margin-bottom: 11px;
}

.chartModule .valueContainer.total {
  padding-top: 11px;
  border-top: 1px solid #EAEAEA;
}

.chartModule .valueContainer .icon {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: -22px;
  margin-bottom: -16px;
}
