.usersModule {
  padding: 25px;
  color: #464646;
}

.usersModule h3 {
  margin-top: 0;
}

.usersModule td, .usersModule th {
  padding: 5px 10px;
}

.usersModule th {
  font-family: PostGrotesk-Medium, sans-serif;
  font-weight: normal;
}

.usersModule tbody tr {
  cursor: pointer;
}

.usersModule tbody tr:hover {
  background: #ededed;
}

.usersModule .adminCell {
  color: #d08585;
}

.usersModule tbody tr.isAdmin .adminCell {
  color: #85d0d0;
}

.usersModule tbody .deleteCell {
  color: #d08585;
}
