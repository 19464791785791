.sustainGapModule {
}

.sustainGapModule .chartArea {
  height: 300px;
  position: relative;
  width: 500px;
}

.sustainGapModule .barContainer {
  height: 100%;
  position: absolute;
  bottom: 0;
}

.onePlanetLine {
  height: 1px;
  background: red;
  position: absolute;
  right: 0;
}

.onePlanetText {
  padding: 5px;
  border: 1px solid #ADADAD;
  position: absolute;
  right: 20px;
  margin-top: 20px;
  background: white;
}

.gapContainer {
  position: absolute;
  display: flex;
  align-items: center;
}

.gapContainer .text {
  margin-left: 10px;
}

.gapContainer .bracketLeft {
  border-radius: 0 5px 5px 0;
  border: 1px solid black;
  border-left: none;
  width: 5px;
  height: 100%;
  box-sizing: border-box;
}

.gapContainer .bracketRightContainer {
  background: white;
  margin-left: -1px;
}

.gapContainer .bracketTop, .gapContainer .bracketBottom {
  width: 5px;
  height: 5px;
}

.gapContainer .bracketTop {
  border-bottom-left-radius: 5px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.gapContainer .bracketBottom {
  border-top-left-radius: 5px;
  border-left: 1px solid black;
  border-top: 1px solid black;
}