.newDatasetForm {
  font-family: PostGrotesk-Light, sans-serif;
}

.newDatasetForm h3 {
  margin-top: 0;
}

.newDatasetForm input[type='text'], .newDatasetForm select {
  margin-bottom: 15px;
  display: block;
  width: 100%;
}

.newDatasetForm .error {
  color: red;
}

.newDatasetForm input[type='text'] {
  font-family: PostGrotesk-Light, sans-serif;
  display: block;
  border: none;
  font-size: 16px;
  color: #444;
  border-bottom: 1px solid #ADADAD;
  height: 35px;
}

.newDatasetForm input::placeholder {
  color: #ADADAD;
}