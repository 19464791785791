html, body {
  padding: 0;
  margin: 0;
  background: #EDEDED;
  font-family: PostGrotesk-Light, sans-serif;
}

@font-face {
  font-family: PostGrotesk-Light;
  src: local('PostGrotesk-Light'), url(./fonts/PostGrotesk-Light.otf) format('opentype');
}

@font-face {
  font-family: 'PostGrotesk-Medium';
  src: local('PostGrotesk-Medium'), url(./fonts/PostGrotesk-Medium.otf) format('opentype');
}

th {
  text-align: left;
}

td {
  padding-right: 1em;
}

input[type="submit"] {
  /*     margin: 2em 3em; */
  font-family: PostGrotesk-Medium, sans-serif;
  width: 185px;
  height: 38px;
  background: #85D0D0;
  color: #555;
  font-size: 14px;
  border-radius: 19px;
  border-style: initial;
}

input[disabled] {
  background: lightgrey;
}

input:focus {
  outline: none;
}

.roundedShadowBox {
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}

a[href], .link {
  color: #85D0D0;
  text-decoration: none;
  cursor: pointer;
}
