.signupSectionContainer {
  background-size: 102% auto;
  background: #EDE8E2 url("../../images/Homepage_Photo_01.jpg") no-repeat center center;
  border: 1px solid transparent;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.signupContainer {
  flex-shrink: 0.2;
  background: white;
  box-sizing: border-box;
  width: 450px;
  margin: 75px 50px;
  padding: 35px 30px 47px;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.formHeader {
  font-size: 24px;
  font-family: PostGrotesk-Medium, sans-serif;
  color: #85D0D0;
  margin: 0;
}

.formHeaderSubtext {
  color: #ADADAD;
  font-size: 16px;
  font-family: PostGrotesk-Light, sans-serif;
}

.switchFormText {
  text-decoration: underline;
  cursor: pointer;
}

.mainSummary {
  padding: 25px;
  color: #464646;
  letter-spacing: 1px;
  margin: 75px 35px;
}

.mainSummary h1 {
  font-weight: normal;
  font-size: 36px;
  line-height: 46px;
  text-shadow: 0 0 14px white;
  margin-top: 0;
  margin-bottom: 28px;
}

.aboutSectionContainer {
  display: flex;
  justify-content: space-between;
  color: #464646;
  background: white;
  padding-left: 50px;
}

.aboutSectionContainer .mainTextContainer {
  width: 371px;
}

.aboutSectionContainer .mainTextContainer h2 {
  font-size: 36px;
  font-weight: normal;
  margin-top: 22px;
  margin-bottom: 32px;
}

.aboutSectionContainer .mainTextContainer p {
  font-size: 16px;
  line-height: 22px;
}

.aboutSectionContainer .rightContainer {
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  padding-left: 50px;
  margin-top: 63px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
}

.aboutSectionContainer .item {
  margin-left: 30px;
  width: 295px;
  margin-bottom: 50px;
}

.aboutSectionContainer .item .number {
  color: white;
  font-size: 16px;
  background: #92D5C3;
  width: 38px;
  height: 38px;
  border-radius: 19px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutSectionContainer .item p {
  margin-left: 59px;
  margin-top: 0;
}

.aboutSectionContainer .item .icon {
  width: 65px;
  height: 65px;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 33px;
}

.aboutSectionContainer .item.one .icon {
  background-image: url("../../images/Homepage_Icon_01.png");
}

.aboutSectionContainer .item.two .icon {
  background-image: url("../../images/Homepage_Icon_02.png");
}

.aboutSectionContainer .item.three .icon {
  background-image: url("../../images/Homepage_Icon_03.png");
}

.aboutSectionContainer .item.four .icon {
  background-image: url("../../images/Homepage_Icon_04.png");
}

.aboutSectionContainer .createAccountButton {
  height: 39px;
  font-size: 16px;
  font-family: PostGrotesk-Medium, sans-serif;
  background: #85D0D0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 28px;
  margin-left: 30px;
  margin-bottom: 68px;
  cursor: pointer;
}

.backgroundSectionContainer {
  display: flex;
  color: #464646;
}

.backgroundSectionContainer .backgroundImage {
  background: #EDE8E2 url("../../images/Homepage_Photo_02.jpg");
  background-size: auto 101%;
  height: 650px;
  max-width: 1035px;
  flex-grow: 1;
}

.backgroundSectionContainer .contentContainer {
  padding-left: 138px;
  padding-right: 61px;
  width: 283px;
  background: #ededed;
}

.backgroundSectionContainer .contentContainer h2 {
  font-size: 36px;
  font-weight: normal;
  margin-top: 50px;
  margin-bottom: 30px;
}

.backgroundSectionContainer .contentContainer p {
  font-size: 16px;
  line-height: 22px;
}
