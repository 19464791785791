.chartModule {
  padding: 23px 33px;
  color: #464646;
}

.chartModule h3 {
  margin: 0 0 30px 0;
  font-size: 26px;
}

.chartModule .chartContainer {
  display: flex;
  flex-flow: row nowrap;
}
