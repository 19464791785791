.pageSelector {
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.pageSelectorItem {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-family: PostGrotesk-Medium, sans-serif;
  padding: 0 32px;
  border-right: 1px solid #D0D0D0;
  color: #D0D0D0;
  cursor: pointer;
}

.pageSelectorItem.selected {
  color: #464646;
}

.pageSelectorItem:last-child {
  border-color: transparent;
}

.pageSelectorItem.selected.food {
  color: #C5CE6E;
}

.pageSelectorItem.selected.buildings {
  color: #FCDD7F;
}

.pageSelectorItem.selected.consumables {
  color: #F5BCAB;
}

.pageSelectorItem.selected.transportation {
  color: #6C5C90;
}

.pageSelectorItem.selected.water {
  color: #50ACC5;
}