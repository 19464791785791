.authForm .error {
  color: red;
}

.authForm .textLink {
  color: #85D0D0;
  cursor: pointer;
  font-family: PostGrotesk-Medium, sans-serif;
}

.authForm .success {
  color: darkgreen;
}

.authForm input[type='text'], .authForm input[type='password'] {
  font-family: PostGrotesk-Light, sans-serif;
  display: block;
  border: none;
  font-size: 16px;
  color: #444;
  border-bottom: 1px solid #444;
  margin-bottom: 6px;
  width: 100%;
  height: 35px;
}

.authForm input::placeholder {
  color: #ADADAD;
}