.dashboard.loading {
  opacity: 0.55;
}

.dashboard .datasetHeader {
  height: 60px;
  margin: 45px 50px;
  display: flex;
  font-size: 16px;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  overflow: hidden;
}

.dashboard .datasetHeader .identifier {
  font-family: PostGrotesk-Medium, sans-serif;
  color: #464646;
  margin-left: 24px;
  margin-right: 26px;
}

.dashboard .datasetHeader .name {
  font-family: PostGrotesk-Medium, sans-serif;
  color: #464646;
  flex: 1 0 auto;
}

.dashboard .datasetHeader .exportDataset, .dashboard .datasetHeader .createNew, .dashboard .datasetHeader .deleteDataset {
  margin-right: 15px;
  color: #ADADAD;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard .datasetHeader .createNew {
  margin-right: 24px;
}

.dashboard .datasetHeader .selector {
  padding-right: 20px;
  padding-left: 20px;
  background: #ADADAD;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.dashboard .popupMenu {
  position: absolute;
  z-index: 100;
}

.dashboard .popupMenu.hidden {
  display: none;
}

.dashboard .datasetHeader .selector .icon {
  width: 12px;
  height: 7px;
  margin-left: 11px;
  background: url("../../images/Down Icon.svg");
}

.dashboardMainContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  margin: 45px 50px;
}

.dashboardContent {
  flex: 1 1 auto;
}

.dashboardContent h3 {
  margin-top: 0;
  font-size: 26px;
  color: #464646;
}

.dashboardContent .sectionGroup {
  display: none;
}

.dashboardContent .sectionGroup.selected {
  display: block;
}

.dashboard .chartModulePair {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
}

.dashboard .chartModulePair .chartModule {
  flex: 1 1 50%;
  margin-bottom: 23px;
}

.dashboard .chartModulePair .chartModule.left {
  margin-right: 23px;
}

.dashboard .notEnoughData {
  font-size: 18px;
  color: #464646;
  padding: 20px;
  flex: 1 1 auto;
}

.dashboardNav {
  flex: 0 0 246px;
  width: 246px;
  margin-right: 76px;
  position: relative;
}

.dashboardNav .triangle {
  position: absolute;
  right: -13px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 13px solid #464646;
}

.dashboardNavGroup {
  margin-bottom: 20pt;
  overflow: hidden;
  height: 60px;
}

.dashboardNavGroup.selected {
  height: auto;
}

.dashboardNavItem {
  box-sizing: content-box;
  font-family: PostGrotesk-Medium, sans-serif;
  font-size: 16px;
  height: 60px;
  border-left: 5px solid #464646;
  padding-left: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ADADAD;
}

.dashboardNavItem.selected {
  background-color: #464646;
  border-color: #464646 !important;
  color: white;
}

.dashboardNavItem .icon {
  display: inline-block;
  width: 27px;
  height: 19px;
  margin-left: -5px;
  margin-right: 5px;
}

.dashboardNavItem .subtitle {
  font-size: 12px;
  font-family: PostGrotesk-Light, sans-serif;
}

.dashboardNavItem:first-child .downIcon {
  width: 12px;
  height: 7px;
  margin-left: auto;
  margin-right: 19px;
  background: url("../../images/Down Icon Grey.svg");
}

.dashboardNavGroup.selected .downIcon {
  display: none;
}

.dashboardNavItem.food {
  border-color: #DEE880;
}

.dashboardNavItem.food .icon {
  background: url(../../images/Apple.svg);
}

.dashboardNavItem.buildings {
  border-color: #FCDD7F;
}

.dashboardNavItem.buildings .icon {
  background: url(../../images/Building.svg);
}

.dashboardNavItem.consumables {
  border-color: #F5BCAB;
}

.dashboardNavItem.consumables .icon {
  background: url(../../images/T-Shirt.svg);
}

.dashboardNavItem.transportation {
  border-color: #6C5C90;
}

.dashboardNavItem.transportation .icon {
  background: url(../../images/Bike.svg);
}

.dashboardNavItem.water {
  border-color: #50ACC5;
}

.dashboardNavItem.water .icon {
  background: url('../../images/Water Droplet.svg');
}