.tooltip {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}

.tooltipTextContainer {
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
  background: white;
  border: 1px solid #444;
  padding: 5px;
  width: 200px;
  z-index: 100;
}

.tooltipTextContainer.visible {
  display: block;
}
